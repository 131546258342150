import React from 'react';
import tw from 'twin.macro';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

const Image = tw.img`pt-4 md:pt-6 w-full`;

export default () => (
  <ArticleLayout
    category="service"
    title="系统集成"
    subtitle="网络架构 / SDK / 中间件 / ERP / CRM / WMS"
  >
    <KeyPoint
      index="○"
      title="范围"
      content="将软件、硬件与通信技术组合起来为用户解决信息处理问题的业务，集成的各个分离部分原本就是一个个独立的系统，集成后的整体的各部分之间能彼此有机地和协调地工作，以发挥整体效益，达到整体优化的目的。"
    />
    <KeyPoint
      index="○"
      title="案例：Sanofi"
      content="企微管理平台 - 构建于企业微信的内容发布与客户关系数据统计和生命周期管理的工具。"
    />
    <Image src="/showcase/sanofi1.jpg" />
    <Image src="/showcase/sanofi2.jpg" />
  </ArticleLayout>
);
